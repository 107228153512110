
import { Component, Vue, Watch } from "vue-property-decorator";
import AuthStore from "@/store/AuthStore";
import ConfirmAccountRequest from "@/models/ConfirmAccountRequest";
import AppEvents from "@/AppEvents";

@Component({
  name: "ConfirmAccount",
  components: {
  },
})
export default class ConfirmAccount extends Vue {
  loading = false;
  invitationCodeValid = false;
  invitationCode = "";

  get rules(): { [key: string]: any; } {
    return {
      required: (v: any) => !!v || this.$t('generic.validation.required'),
    }
  }

  onSignoutClick() {
    this.$root.$emit(AppEvents.SIGNOUT_FROM_APP);
  }

  async onConfirmAccountClick() {
    if (!(this.$refs.confirmAccountForm as any).validate()) {
      return;
    }
    this.loading = true;

    const request = new ConfirmAccountRequest(this.invitationCode);
    const accountConfirmSuccess = await AuthStore.confirmAccount(request);
    if (accountConfirmSuccess) {
      this.$root.$emit(AppEvents.REDIRECT_TO_FRONTEND);
    }
    this.loading = false;
  }
}
